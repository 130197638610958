import robot from "./robot.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={robot} className="App-logo" alt="robot" />
      </header>
    </div>
  );
}

export default App;
